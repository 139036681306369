import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import useAxios from 'axios-hooks';
import { omit, size } from 'lodash';

import { API_URL } from 'environments';

const URL = `${API_URL}/cempro-validas`;

export default function CemproField({
	label = 'Empresa de Propaganda',
	labelEnum,
	required,
	name,
	value,
	isInvalid,
	disabled,
	readOnly,
	onChangeHandler
}) {
	const [permitValidate, setPermitValidate] = useState(false);
	const [erros, setErros] = useState();
	const [limparValue, setLimparValue] = useState(false);

	let [{ data: cemproList, loading: cemproLoading, error: cemproError }, execute] = useAxios(URL, {
		manual: true,
		useCache: false
	});

	const options = useMemo(() => {
		if (cemproLoading) {
			return [];
		}
		let optionsAux = [];

		if (cemproList && !cemproError) {
			optionsAux = cemproList.map(cempro => ({
				id: cempro._id,
				...omit(cempro, ['_id', 'operations', 'updatedAt', 'createdAt', 'user'])
			}));
		}
		if (size(optionsAux) === 0 && permitValidate) {
			setLimparValue(true);
		}
		return optionsAux;
	}, [cemproLoading, cemproList, cemproError, permitValidate]);

	const onChangeSelect = useCallback(
		ev => {
			let { value } = ev?.target || {};
			value = value === 'none' ? null : cemproList.find(cempro => cempro._id === value);
			const errorsAux = { [name]: validate(value, label, required) };
			const changes = [{ name, value }];
			if (size(errorsAux) > 0) {
				changes.push({ name: 'errors', value: errorsAux });
			}
			onChangeHandler(changes);
		},
		[cemproList, label, name, onChangeHandler, required]
	);

	useEffect(() => {
		execute().finally(() => setPermitValidate(true));
	}, [execute]);

	useEffect(() => {
		if (cemproError) {
			setLimparValue(true);
		}
	}, [cemproError]);

	useEffect(() => {
		if (permitValidate && !cemproLoading) {
			if (cemproError) {
				console.error(cemproError);
				setPermitValidate(false);
			} else {
				setErros({});
			}
		}
	}, [cemproList, cemproError, cemproLoading, name, permitValidate]);

	useEffect(() => {
		if (size(erros) > 0 && !cemproLoading) {
			onChangeHandler({ name: 'errors', value: erros });
		}
	}, [erros, cemproLoading, onChangeHandler]);

	const idSelecionado = useMemo(() => {
		if (value) {
			return value.id;
		}
		return 'none';
	}, [value]);

	useEffect(() => {
		if (limparValue) {
			if (value) {
				onChangeHandler([
					{ name, value: null },
					{ name: 'errors', value: { [name]: validate(null, label, required) } }
				]);
			}
			setLimparValue(false);
		}
	}, [label, name, onChangeHandler, required, limparValue, value]);

	return (
		<>
			<label className="control-label" htmlFor={`id_${name}`}>
				{labelEnum || label}
				{required && <span className="required">*</span>}
			</label>
			<select
				className={isInvalid ? 'form-control form-control-error' : 'form-control'}
				name={name}
				id={`id_${name}`}
				value={idSelecionado}
				onChange={onChangeSelect}
				disabled={disabled || readOnly}
			>
				{options && (
					<>
						<option value="none">
							{size(options) > 0 ? 'Selecione uma opção' : 'A empresa de propaganda deve ser informada'}
						</option>
						{options
							.filter(o => !!o)
							.map(opt => (
								<option value={opt.id} key={opt.id}>
									{opt.nome}
								</option>
							))}
					</>
				)}
			</select>
		</>
	);
}

CemproField.displayName = 'CemproField';

CemproField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	forTemplate: PropTypes.bool,
	isInvalid: PropTypes.bool,
	readOnly: PropTypes.bool,
	expedienteValue: PropTypes.object,
	ultimo: PropTypes.bool
};

export const validate = (value, label, required) => {
	let errors = [];
	if (required) {
		if (!value || value.length === 0) {
			errors.push(`${label} deve ser informada`);
		}
	}
	return errors;
};
